
<script>
    export const last_updated = '13 Sep 2023'
</script>


<h2>What I'm currently working on</h2>
<p class="regular_text">
    Currently, I'm spending most of my time on 'Galahad', an algorithmic trading program, which is the reincarnation of Jefferson-IE (previously mentioned here).
    Galahad is designed to be more flexible, and use more modern technologies, such as Docker and Cloudflare R2 Storage. More updates on this soon.
     <br><br>

    I've recently finished my third year CS degree project, which is an AI for the board game <a href="https://en.wikipedia.org/wiki/Catan">Settlers of Catan</a>.
    This involved implementing different strategies for a range of AI players, and comparing which ones work the best.
    If you want to see the project, or read the full report, checkout the <a href="#projects">Projects</a> section below.
</p>
<p class="last_updated">Last Updated: {last_updated}</p>

<style>

    h2 {
        font-size:calc(min(10px + 3vw, 40px));
        font-weight: 500;
        text-align: center;
        letter-spacing: 2px;
        color: white;
        margin: 80px 20px 20px 20px;

    }

    a{
        text-decoration: underline;
        color: white;
    }

    .last_updated {
        font-size: calc(min(8px + 2vw, 20px));
        color: #999;
        text-align: center;
        margin-bottom: 60px;
    }

</style>