
<div class = 'container' id ="about">
    <h1>About</h1>
    <p class="regular_text">
        My name's Harrison, and I'm an MSc Computer Science student studying at the University of Essex.
        I'm currently in my final year, and am looking for a job in the industry for when I graduate. My main areas of interest
        include Artificial Intelligence, Neural Nets, Machine Learning, and Fintech, but I'm always open to new things!<br><br>
        Outside of Computer Science, I'm a lover of the outdoors, and enjoy running, hiking and camping. I also maintain a
        homelab, and enjoy tinkering with new technologies and software in my spare time.
    </p>

</div>


<style>
    .container{
        margin: 2vh 4vw 4vw 4vw;
        color: white;
        line-height: 1.1;
        padding-bottom: 30px;
    }

    h1 {
        font-size: calc(min(25px + 8vw, 80px));
        font-weight: 600;
        text-align: center;
        letter-spacing: 2px;
    }

    h2 {
        font-size:calc(min(10px + 3vw, 40px));
        font-weight: 500;
        text-align: center;
        letter-spacing: 2px;
        padding-top: 20px;
    }

</style>